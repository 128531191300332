import clsx from 'clsx';
import { HOMEPAGE_COUPON_ICON, HOMEPAGE_FAST_DELIVERY_ICON, HOMEPAGE_GLOBAL_ICON, HOMEPAGE_SUPPORT_ICON } from 'constants/Images';
import { COMPREHENSIVE_RANGE, FAST_DELIVERY, QUALITY_PRODUCT, SUPPORT } from 'constants/Images/imageStaticCloud';
import React from 'react';
import { ImageFallbackStoreImage } from 'utils/ImageFallback';
import { getLinkCacheProxy, getLinkProxy } from 'utils/ImageUtils';
import styles from './style.module.css';

const WhyBuymedItem = ({ image, icon, title, subTitle, fallbackSrc, isMobile }) => (
  <div className={styles.whyBuymedItemWrapper}>
    <ImageFallbackStoreImage className={styles.image} layout="fill" src={image} fallbackSrc={fallbackSrc} alt={title} width={302} height={172} />
    <div className={styles.titleWrapper}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.subTitle}>{subTitle}</p>
      {!isMobile && <img src={icon} width={32} height={32} alt={title} className={styles.icon} />}
    </div>
  </div>
);

const WhyBuymed = ({ t, isMobile = false }) => (
  <div className={clsx(!isMobile ? styles.whyBuymedWrapper : styles.mobileWhyBuymedWrapper)}>
    <WhyBuymedItem
      title={t('common:whyBuymed.quality')}
      subTitle={t('common:whyBuymed.from')}
      image={getLinkCacheProxy({ url: QUALITY_PRODUCT, w: 500 })}
      fallbackSrc={getLinkProxy(QUALITY_PRODUCT)}
      icon={HOMEPAGE_COUPON_ICON}
      isMobile={isMobile}
    />
    <WhyBuymedItem
      title={t('common:whyBuymed.fast_delivery')}
      subTitle={t('common:whyBuymed.safeOrder')}
      image={getLinkCacheProxy({ url: FAST_DELIVERY, w: 500 })}
      fallbackSrc={getLinkProxy(FAST_DELIVERY)}
      icon={HOMEPAGE_FAST_DELIVERY_ICON}
      isMobile={isMobile}
    />
    <WhyBuymedItem
      title={t('common:whyBuymed.news_profession')}
      subTitle={t('common:whyBuymed.definitelyNews')}
      image={getLinkCacheProxy({ url: COMPREHENSIVE_RANGE, w: 500 })}
      fallbackSrc={getLinkProxy(COMPREHENSIVE_RANGE)}
      icon={HOMEPAGE_GLOBAL_ICON}
      isMobile={isMobile}
    />
    <WhyBuymedItem
      title={t('common:whyBuymed.profession_teams')}
      subTitle={t('common:whyBuymed.fcd_and_t')}
      image={getLinkCacheProxy({ url: SUPPORT, w: 500 })}
      fallbackSrc={getLinkProxy(SUPPORT)}
      icon={HOMEPAGE_SUPPORT_ICON}
      isMobile={isMobile}
    />
  </div>
);

export default React.memo(WhyBuymed);
