import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { MOBILE_PARTNER, PARTNER } from 'constants/Images/imageStaticCloud';
import { imageMediaThaiLan } from 'constants/data';
import { ImageFallbackStoreImage } from 'utils/ImageFallback';
import { getLinkCacheProxy, getLinkProxy } from 'utils/ImageUtils';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.css';

const renderMediaItemImage = (data, lang = 'kh', isMobile = false) =>
  data.map((item) => (
    <Grid item xs={!isMobile ? 4 : 12} key={`media-${uuidv4()}`} className={clsx(!isMobile ? styles.hover_link : styles.mobile_hover_link)}>
      <a
        href={item.href}
        target="_blank"
        rel="noreferrer"
        style={{
          display: 'block',
          width: '100%',
          paddingTop: !isMobile ? '50%' : '39%',
          height: 'unset',
        }}
      >
        <ImageFallbackStoreImage
          className={item.dark && styles.dark}
          src={getLinkCacheProxy({ url: item[!isMobile ? `urlImage_${lang}` : `urlImage_mobile_${lang}`], w: 500 })}
          fallbackSrc={getLinkProxy(item[!isMobile ? `urlImage_${lang}` : `urlImage_mobile_${lang}`])}
          layout="fill"
          objectFit="contain"
        />
      </a>
    </Grid>
  ));

const Media = ({ t, lang, isMobile = false }) => (
  <>
    <div className={clsx(!isMobile ? styles.media_wrapper : styles.mobile_media_wrapper)}>
      <Typography variant="h4" align="center" className={clsx(!isMobile ? styles.media_title : styles.mobile_media_title)}>
        {t('landingPage:what_media_says_about_thuocsi')}
      </Typography>
      <Grid container justifyContent="space-between" alignItems="center">
        {renderMediaItemImage(imageMediaThaiLan, lang, isMobile)}
      </Grid>
    </div>
    <div className={clsx(!isMobile ? styles.media_wrapper : styles.mobile_media_wrapper)}>
      <Typography variant="h4" align="center" className={clsx(!isMobile ? styles.media_title : styles.mobile_media_title)}>
        {t('landingPage:our_partners')}
      </Typography>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 'unset',
          paddingTop: !isMobile ? '28%' : '111%',
        }}
      >
        <ImageFallbackStoreImage
          src={isMobile ? getLinkCacheProxy({ url: MOBILE_PARTNER, w: 500 }) : getLinkCacheProxy({ url: PARTNER, w: 1500 })}
          fallbackSrc={isMobile ? getLinkProxy(MOBILE_PARTNER) : getLinkProxy(PARTNER)}
          layout="fill"
          objectFit="cover"
        />
      </div>
    </div>
  </>
);

export default Media;
