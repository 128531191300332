import { Grid, Tab, Tabs, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { CONNECTION, MOBILE_CONNECTION, MOBILE_MISSION, MOBILE_VISION, VISION } from 'constants/Images/imageStaticCloud';
import { useState } from 'react';
import { ImageFallbackStoreImage } from 'utils/ImageFallback';
import { getLinkCacheProxy, getLinkProxy } from 'utils/ImageUtils';
import styles from './styles.module.css';

function CustomTabPanel(props) {
  const { children, value, id, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== id} id={`simple-tabpanel-${id}`} aria-labelledby={`simple-tab-${id}`} {...other}>
      {value === id && <div>{children}</div>}
    </div>
  );
}

const AboutUs = ({ isMobile = false, t }) => {
  const [tab, setTab] = useState(0);

  const tabList = [
    {
      id: 0,
      title: t('common:aboutUs.connection'),
      content: ['one', 'two', 'three'].map((part) => <p className={styles.content}>{t(`common:aboutUs.connection_content_${part}`)}</p>),
      image: isMobile ? getLinkCacheProxy({ url: MOBILE_CONNECTION, w: 500 }) : getLinkCacheProxy({ url: CONNECTION, w: 500 }),
      imageFallback: isMobile ? getLinkProxy(MOBILE_CONNECTION) : getLinkProxy(CONNECTION),
    },
    {
      id: 1,
      title: t('common:aboutUs.vision'),
      content: ['one', 'two', 'three'].map((part) => <p className={styles.content}>{t(`common:aboutUs.vision_content_${part}`)}</p>),
      image: isMobile ? getLinkCacheProxy({ url: MOBILE_VISION, w: 500 }) : getLinkCacheProxy({ url: VISION, w: 500 }),
      imageFallback: isMobile ? getLinkProxy(MOBILE_VISION) : getLinkProxy(VISION),
    },
    {
      id: 2,
      title: t('common:aboutUs.mission'),
      content: ['one', 'two', 'three'].map((part) => <p className={styles.content}>{t(`common:aboutUs.mission_content_${part}`)}</p>),
      image: isMobile ? getLinkCacheProxy({ url: MOBILE_MISSION, w: 500 }) : getLinkCacheProxy({ url: MOBILE_MISSION, w: 500 }),
      imageFallback: isMobile ? getLinkProxy(MOBILE_MISSION) : getLinkProxy(MOBILE_MISSION),
    },
  ];

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <div className={styles.about_wrapper}>
      <Typography variant="h4" align="center" className={styles.about_title}>
        {t('landingPage:about_us')}
      </Typography>
      <Grid container>
        <Grid item sm={6} xs={12} className={styles.tab_container}>
          <Tabs value={tab} onChange={handleChangeTab} className={styles.tab_wrapper}>
            {tabList.map((tabItem) => (
              <Tab
                key={tabItem.id}
                value={tabItem.id}
                label={tabItem.title}
                id={`simple-tab-${tabItem.id}`}
                aria-controls={`simple-tabpanel-${tabItem.id}`}
                className={clsx(styles.tab_item, { [styles.tab_item_active]: tab === tabItem.id })}
              />
            ))}
          </Tabs>
          {tabList.map((tabItem) => (
            <CustomTabPanel key={tabItem.id} value={tab} id={tabItem.id}>
              {tabItem.content}
            </CustomTabPanel>
          ))}
        </Grid>
        <Grid item sm={6} xs={12} className={clsx(styles.tab_container, styles.tab_images)}>
          {tabList.map((tabItem) => (
            <div className={styles.image_container} style={{ display: tabItem.id === tab ? 'block' : 'none' }}>
              <ImageFallbackStoreImage
                src={tabItem.image}
                fallbackSrc={tabItem.imageFallback}
                layout="fill"
                objectFit="contain"
                className={styles.image}
              />
            </div>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutUs;
